import image1 from "../images/gallery/commerical-1.jpg";
import image2 from "../images/gallery/commerical-2.jpg";
import image3 from "../images/gallery/commerical-3.jpg";
import image4 from "../images/gallery/commerical-4.jpg";
import image5 from "../images/gallery/commerical-5.jpg";
import image6 from "../images/gallery/commerical-6.jpg";
import image7 from "../images/gallery/commerical-7.jpg";

export const photos = [
	{
    src: image1,
    width: 6,
    height: 3
  },
  {
    src: image2,
    width: 6,
    height: 3
  },
  {
    src: image3,
    width: 6,
    height: 3
  },
  {
    src: image4,
    width: 6,
    height: 3
  },
  {
    src: image5,
    width: 6,
    height: 3
  },
  {
    src: image6,
    width: 6,
    height: 3
  },
  {
    src: image7,
    width: 6,
    height: 3
  }
];
